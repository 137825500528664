:root {
	--tertiary--1: #17161c;
	--secondary--3: #7a1dff;
	--theme--5: #888596;
	--theme--3: #e4e1ed;
	--theme--1: #f8f7fc;
	--primary--3: #ffcc29;
	--primary--1: #fef5d3;
	--primary--2: #ffe182;
	--theme--4: #b2aebd;
	--secondary--4: #6012d0;
	--primary--4: #f1bf20;
	--theme--2: #f3f2f7;
	--secondary--1: #f2e8ff;
	--tertiary--2: #23212c;
	--tertiary--3: black;
	--primary--5: #c99900;
	--secondary--2: #af77ff;
	--secondary--5: #5206bf;
	--accessible-components-dark-grey: #9b9b9b;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'IBM Plex Sans', 'Fira Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
	height: 100%;
	font-size: 2.5vw;
}

body {
	margin: 0;
	min-height: 100%;
	background-color: #fff;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	color: #333;
	font-family:
		IBM Plex Sans,
		sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

input:focus {
	border-color: #3898ec;
	outline: 0;
	border-style: none;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('../fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cairo';
	src: url('../fonts/Cairo-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
