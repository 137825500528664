.modal-overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	border-radius: 0;
	margin: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 777;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
