.about-game-modal-overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	border-radius: 0;
	margin: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 778;
	padding: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-game-modal {
	position: relative;
	padding: 2rem;
	box-sizing: border-box;
	background: #fff;
	border-radius: 0.3rem;
	width: 100%;
}

.about-game-modal-close {
	top: .1rem;
	right: .7rem;
	position: absolute;
	width: .5rem;
}

.about-game-modal-close img {
	width: .5rem;
}

.about-game-modal-title {
	margin-top: 0.7rem;
	color: var(--tertiary--1);
	font-size: 1rem;
	font-family: 'Cairo';
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: 0.2rem;
	text-align: center;
}

.about-game-modal-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 0.6rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.about-game-modal-img {
	height: 7rem;
	border-radius: 0.4rem;
}

.about-game-modal-img img {
	width: 100%;
}

.about-game-modal-info {
	height: 7rem;
	border-radius: 0.4rem;
	background: #F3F2F7;
	width: 100%;
	box-sizing: border-box;
	padding: 0.9rem;
}

.about-game-modal-row {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 0.4rem;
}

.about-game-modal-line {
	width: 1px;
	height: 1rem;
	background: #E4E1ED;
	margin: 0 1rem;
}

.about-game-modal-item {
	display: block;
	width: 100%;
}

.about-game-modal-item_label {
	color: #888596;
	font-size: 0.5rem;
	font-weight: 500;
	text-transform: uppercase;
}

.about-game-modal-item_value {
	margin-top: 0.2rem;
	color: #000;
	font-size: 0.7rem;
	height: 1rem;
	font-weight: 700;
}

.about-game-modal-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.3rem;
}

.about-game-modal-btn {
	padding: 0.5rem 1rem;
	background: var(--primary--3);
	color: var(--tertiary--1);
	box-sizing: border-box;
	border: none;
	font-size: 0.5rem;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 0.3rem;
	cursor: pointer;
}

.about-game-drop-text {
	margin-top: 1.5rem;
	color: #17161C;
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 400;
}

.about-game-drop-container {
	margin-top: 0.6rem;
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}

.about-game-drop-item {
	width: 100%;
}

.about-game-drop-item-title {
	color: #17161C;
	font-size: 0.6em;
	font-weight: 700;
}

.about-game-drop-item-text {
	margin-top: 0.2rem;
	color: #17161C;
	font-size: 0.5rem;
	font-weight: 400;
}
