.contact-us-modal-overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	border-radius: 0;
	margin: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 777;
	padding: 2rem calc(50% - 10rem);
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact-us-modal {
	position: relative;
	padding: 2rem;
	box-sizing: border-box;
	background: #fff;
	border-radius: 0.3rem;
	width: 100%;
}

.contact-us-modal-close {
	top: .1rem;
	right: .7rem;
	position: absolute;
	width: .5rem;
}

.contact-us-modal-close img {
	width: .5rem;
}

.contact-us-modal-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.contact-us-modal-title {
	margin-top: 0.7rem;
	color: var(--tertiary--1);
	font-size: 1rem;
	font-family: 'Cairo';
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: 0.2rem;
	text-align: center;
}

.contact-us-modal-img {
	margin-top: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 8rem;
}

.contact-us-modal-img img {
	width: 100%;
}

.contact-us-modal-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.3rem;
}

.contact-us-modal-btn {
	padding: 0.5rem 1rem;
	background: var(--primary--3);
	color: var(--tertiary--1);
	box-sizing: border-box;
	border: none;
	font-size: 0.5rem;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 0.3rem;
	cursor: pointer;
}
