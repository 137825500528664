.game-modal {
	top: 0;
	left: 0;
	position: fixed;
	background-color: #fff;
	width: 100%;
	height: 100%;
	border-radius: 0;
	padding: 0;
	margin: 0;
	overflow-y: auto;
	z-index: 777;
	box-sizing: border-box;
}

.game-modal-template {
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	box-sizing: border-box;
}

.game-modal_banner {
	padding: 1rem 1rem 0 1rem;
	box-sizing: border-box;
}

.game-modal-iframe_wrapper {
	width: 100%;
	height: 28rem;
	background-color: #000000;
}

.game-modal-iframe {
	width: 100%;
	height: 100%;
	border: none;
}
.game-modal-content_template {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	gap: 0.5rem;
	padding: 0 1rem;
	box-sizing: border-box;
	overflow: hidden;
	margin-top: 0.8rem;
}
.game-modal-button_container {
	display: flex;
	gap: 10px;
	width: 100%;
}

.game-modal-button {
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 0.5rem;
	border: none;
	font-weight: bold;
	width: 100%;
	cursor: pointer;
}
.game-modal-button:active {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.game-modal-button_back {
	background-color: #ffffff;
	color: #000000;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.game-modal-button_similar {
	background-color: #ffcc29;
	color: #000000;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.game-modal-empty_block {
	width: 100%;
	height: 2.5rem;
}

.game-modal_tablet-buttons {
	display: none;
}

.game-modal_tablet-center {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.game-modal_tablet-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.3rem;
	background: #FFCC29;
	padding: 0.3rem 0.7rem;
	gap: 0.34rem;
	text-transform: uppercase;
}

.game-modal_tablet-btn span {
	text-align: center;
	font-weight: 600;
	color: #231F20;
	text-transform: uppercase;
	font-size: 0.8rem;
}

.game-modal_tablet-btn img {
	width: 0.56rem;
	height: auto;
}

.game-modal_tablet-lang {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.4rem;
	border: 1px solid #FFCC29;
	box-sizing: border-box;
	padding: 1px;
}

.game-modal_tablet-lang-btn {
	border-radius: 0.4rem;
	width: 100%;
	text-align: center;
	font-weight: 600;
	text-transform: uppercase;
	color: #FFCC29;
	border: 1px solid rgba(0,0,0,0);
	font-size: 0.8rem;
	padding: 0.2rem 0.4rem;
	box-sizing: border-box;
}

.game-modal_tablet-lang-btn_active {
	border-radius: 0.3rem;
	background: #FFCC29;
	width: 100%;
	text-align: center;
	font-weight: 600;
	color: #231F20;
	text-transform: uppercase;
	font-size: 0.8rem;
	padding: 0.2rem 0.4rem;
	box-sizing: border-box;
}

.game-modal-iframe_wrapper_mobile {
	height: calc(100svh - 4rem);
}

.game-modal_tablet-buttons_mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0.8rem;
	background: #151312;
	height: 4rem;
}

.game-modal_mobile {
	display: block;
	background: #151312;
}

.game-modal-template_mobile {
	overflow: hidden;
}

.game-modal_banner_mobile {
	display: none;
}

.game-modal-content_template_mobile {
	display: none;
}
