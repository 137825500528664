body {
	touch-action: pan-x pan-y;
}

.banner {
	width: 100%;
	margin-bottom: 0.5rem;
}

.banner img {
	object-fit: cover;
	width: 100%;
	border-radius: 0.5rem;
}

.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--theme--2);
	gap: 0.55rem;
	box-sizing: border-box;
	padding: 0.3rem 0;
	margin-bottom: 0.6rem;
	border-radius: 0.5rem;
}

.tabs-filters a {
	text-decoration: none;
}

.tab {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.15rem;
	height: 1.5rem;
	border: none;
	color: var(--tertiary--1);
	background-color: transparent;
	padding: 0 0.75rem;
	font-size: 0.5rem;
	line-height: 0.875rem;
	text-align: center;
	text-transform: uppercase;
	border-radius: 0.2rem;
	font-weight: 700;
	transition: all 0.14s ease-in-out;
	cursor: pointer;
}

.tab.active,
.tab:active {
	background: var(--primary--3);
}

.tab-number {
	background: var(--tertiary--1);
	color: var(--primary--3);
	padding: 0 0.25rem;
	font-weight: normal;
	border-radius: 0.2rem;
}

.tabs-filters {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.55rem;
	box-sizing: border-box;
	margin-bottom: 0.6rem;
}

.tab-filter {
	border: 1px solid var(--theme--3);
}

.games-container {
	position: relative;
	width: calc(100% - 0.1rem);
	box-sizing: border-box;
	padding-left: 0.1rem;
}

.games-container-scroll {
	display: flex;
	width: calc(100% + 0.7rem);
	padding-left: 5.5rem;
	padding-bottom: 0.5rem;
	overflow-x: auto;
}

.games-sidebar-wrapper {
	position: absolute;
	top: -3px;
	left: -3px;
	background: white;
	height: calc(100% - 0.6rem);
	width: 5rem;
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.games-sidebar-container {
	background: var(--primary--3);
	color: var(--tertiary--1);
	height: calc(100% + 0.02rem);
	font-family: 'Cairo';
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.3rem;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0 0.52rem;
}

.games-sidebar {
	display: block;
}

.games-sidebar-title {
	font-weight: 900;
	font-size: 0.8rem;
	line-height: 1rem;
	text-align: center;
	text-transform: uppercase;
}

.games-sidebar-subtitle {
	margin-top: 0.7rem;
	font-weight: 700;
	font-size: 0.4rem;
	text-align: center;
	text-transform: uppercase;
}

.games-sidebar-img {
	margin-top: 0.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.games-sidebar-img img {
	width: 0.6rem;
}

.games-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 0.4rem;
	min-width: 53.6rem;
}

.games-list-nowrap {
	flex-wrap: nowrap;
	min-width: auto;
}

.game-card {
	width: 5rem;
	height: 6rem;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	border-radius: 0.4rem;
}

.static-containers {
	display: flex;
	gap: 0.6rem;
}

.casinobeats-container {
	display: flex;
	flex: 1;
	justify-content: space-between;
	background: var(--secondary--3);
	padding: 0.6rem 0.7rem;
	box-sizing: border-box;
	border-radius: 0.3rem;
}

.casinobeats-content {
	color: #fff;
}

.casinobeats-title {
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 900;
	font-family: 'Cairo';
	text-transform: uppercase;
	width: 8rem;
}

.casinobeats-row {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	font-size: 0.5rem;
	font-weight: 900;
	font-family: 'Cairo';
	text-transform: uppercase;
}

.casinobeats-row img {
	margin-right: 0.3rem;
}

.casinobeats-img {
	display: flex;
	justify-content: flex-end;
	width: 6rem;
}

.casinobeats-img img {
	margin-top: -1.2rem;
	width: 100%;
	height: auto;
	object-fit: contain;
}

.bgaming-thumbs-container {
	display: flex;
	flex: 1;
	justify-content: flex-start;
	background: var(--primary--3);
	padding: 0.6rem 0.7rem;
	box-sizing: border-box;
	border-radius: 0.3rem;
}

.bgaming-thumbs-img {
	width: 5.5rem;
}

.bgaming-thumbs-img img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.bgaming-thumbs-content {
	margin-left: 0.5rem;
	color: var(--tertiary--1);
}

.bgaming-thumbs-title {
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 900;
	font-family: 'Cairo';
	text-transform: uppercase;
	width: 8rem;
}

.bgaming-thumbs-text {
	display: flex;
	align-items: center;
	margin-top: 0.4rem;
	font-size: 0.5rem;
	font-family: 'Cairo';
	width: 9rem;
}

@media screen and (max-width: 767px) {
	.games-sidebar-container {
		height: calc(100% + 0.35rem);
	}
}

@media screen and (max-width: 1024px) {
	.games-sidebar-container {
		height: calc(100% + 0.15rem);
	}
}
